import { KeyboardEvent, useContext } from 'react';
import { useRouter } from 'next/router';
import { IRouteListItem, Navbar } from '@ascd/witsby-components';
import { Box } from '@mui/material';
import OnboardModal from '@components/OnboardModal';
import Walkme from '@components/Walkme';
import { MOBILE_NAV_BAR_LIST, NAV_BAR_LIST } from '@constants';
import { AppContext } from '@contexts/appContext';
import { detectDeviceIsMobile } from '@utils/checkDevice';
import { usePresignedImage, useMyAccount } from '@utils/hooks';

export interface ILayoutProps {
  children?: JSX.Element;
  showBreadcrumbs?: boolean;
  extra?: JSX.Element | null;
  breadcrumbsList?: IRouteListItem[];
}

const Layout = ({ children, showBreadcrumbs, extra, breadcrumbsList }: ILayoutProps) => {
  const router = useRouter();
  const isMobile = detectDeviceIsMobile();
  const { user, signOut } = useMyAccount();
  const [avatarProfileUrl] = usePresignedImage(user.avatar);
  const {
    state: {
      currentUser,
      currentUser: { modalOff },
      currentUserRole: { isInstitutionalAdmin, isManager, isAscdAdmin, isAuthor },
    },
  } = useContext(AppContext);

  const checkIsShowBreadcrumbs = () => {
    const pathName = router.pathname.split('/');
    if (showBreadcrumbs) {
      return [];
    }
    if (isMobile) {
      return MOBILE_NAV_BAR_LIST.map((n) => ({
        ...n,
        isActive: !pathName[1] ? n.pathName === '/' : n.pathName.includes(pathName[1]),
      }));
    }

    let deskTopNavBarList = NAV_BAR_LIST.map((n) => ({
      ...n,
      isActive: !pathName[1] ? n?.pathName === '/' : n?.pathName?.includes(pathName[1]),
    }));

    if (!(isInstitutionalAdmin || isManager || isAscdAdmin || isAuthor)) {
      deskTopNavBarList = deskTopNavBarList.filter((v) => v.pageName !== 'Admin');
    }

    return deskTopNavBarList;
  };

  const onSearch = (value: string) => {
    if (!value) {
      router.push(`/search`);
    } else {
      router.push(`/search?searchValue=${value}`);
    }
  };

  const onSearchEnter = (e: KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      router.push(`/search?searchValue=${value}`);
    }
  };

  const newTabUrl = (pathName: string) =>
    pathName.startsWith('http') || pathName.startsWith('https')
      ? pathName
      : window.location.origin + pathName;

  return (
    <>
      <Box
        sx={(theme) => ({
          '& .css-1l1lna-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'lightgrey ',
          },
          '& .css-37otwr-MuiContainer-root': {
            paddingLeft: '0 ',
          },
          '& .MuiInputBase-root': {
            [theme.breakpoints.up('lg')]: {
              width: '194px',
            },
            [theme.breakpoints.down('lg')]: {
              width: '106px',
            },
            height: '32px',
          },
          '&& fieldset': {
            backgroundColor: '#E1E1E1',
            [theme.breakpoints.down('lg')]: {
              border: ' 0px',
            },
          },
          '& .css-cbcivg': {
            [theme.breakpoints.down('md')]: {
              minWidth: '264px',
            },
            [theme.breakpoints.down('lg')]: {
              minWidth: '270px',
            },
          },
          '& .css-8fhx1k': {
            [theme.breakpoints.up('lg')]: {
              minWidth: '320px',
            },
            [theme.breakpoints.down('lg')]: {
              minWidth: '264px',
            },
          },
          '& .css-1l1lna-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input': {
            [theme.breakpoints.up('lg')]: {
              padding: '10px  0px 10px 33px',
            },
            [theme.breakpoints.down('lg')]: {
              padding: ' 0px  0px 0px 10px',
            },
          },
          '& div.MuiFormControl-root svg': {
            width: '1rem !important',
            height: '1rem !important',
          },
          '& .css-1fk7u14': {
            minWidth: '270px',
          },
        })}>
        <Walkme />
        <Navbar
          extra={extra}
          showBreadcrumbs={showBreadcrumbs}
          breadcrumbsList={breadcrumbsList}
          hideBoxShadow={isMobile}
          onLogoClick={() => router.push('/')}
          onClick={(pathName: string, openInNewTab?: boolean) =>
            openInNewTab
              ? window.open(newTabUrl(pathName), '_blank', 'noopener, noreferrer')
              : router.push(pathName)
          }
          navList={checkIsShowBreadcrumbs()}
          searchData={onSearch}
          onKeyEvent={onSearchEnter}
          href="/profile"
          signOut={signOut}
          session={{
            ...user,
            avatar: avatarProfileUrl,
            givenName: currentUser?.firstName || user.givenName,
            familyName: currentUser?.lastName || user.familyName,
            witsbyRole: user.witsbyRole || currentUser.witsbyRole,
          }}
          // isLearner={isLearner}
        />
        {!modalOff && <OnboardModal />}
      </Box>
      {children}
    </>
  );
};
export default Layout;
